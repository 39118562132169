import React, { Component } from 'react';
import QRCode from 'qrcode.react';

class QRCodeScanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scannedURL: null,
    };
  }

  handleScan = (data) => {
    if (data) {
      window.location.href = data; // Open the scanned URL in the current window
    }
  };

  handleError = (error) => {
    console.error(error);
  };

  render() {
    const { scannedURL } = this.state;
    const {width,height} = this.props;
    let windowUrl = window.location.origin;
    return (
      // {scannedURL && (
      //   <div>
      //     <p>Scanned URL: {scannedURL}</p>
      //     {/* Perform action with the scanned URL */}
      //   </div>
      // )}
      <div>
        <QRCode value={windowUrl} size={width} />
      </div>
    );
  }
}

export default QRCodeScanner;
