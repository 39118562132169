import React, { Component } from "react";

// import FirstScreen from "./FirstScreen";
import { Redirect } from "react-router";

class Mobile extends Component {
  state = {
    showGdpr: false,
    redirect: null
  };
  componentDidMount() {
    setTimeout(() => {
      if (document.getElementsByClassName("popup-content")[0]) {
        document.getElementsByClassName(
          "popup-content"
        )[0].style.backgroundColor = "transparent";
      }
    }, 100);

    if (!localStorage.getItem("gdprAccepted")) {
      localStorage.setItem("gdprAccepted", "false");
      if (localStorage.getItem("showGdpr") === "true") {
        this.setState({ showGdpr: true });
      }
    }

    if (
      localStorage.getItem("showGdpr") === "true" &&
      localStorage.getItem("gdprAccepted") === "false"
    ) {
      this.setState({ showGdpr: true });
    }
    setTimeout(() => {
      this.removeSplashScreen();
    }, 1000);
  }
  handleGdprClick = () => {
    localStorage.setItem("gdprAccepted", "true");
    this.setState({ showGdpr: false });
  };

  removeSplashScreen = () => {
    if (document.getElementById("firstScreenSplash")) {
      document.getElementById("firstScreenSplash").remove();
    }
    this.setState({redirect:'/home'})
  };

  render() {
    if (this.state.redirect !== null) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <React.Fragment>
        {this.state.showGdpr && (
          <div className="fixed-gdpr-mobile">
            <span
              dangerouslySetInnerHTML={{
                __html: localStorage.getItem("gdprMessage"),
              }}
            ></span>
            <span>
              <button
                className="btn btn-sm ml-2"
                style={{ backgroundColor: localStorage.getItem("storeColor") }}
                onClick={this.handleGdprClick}
              >
                {localStorage.getItem("gdprConfirmButton")}
              </button>
            </span>
          </div>
        )}

        <div>
          <div className="col-12 p-0" id="firstScreenSplash">
            <div className="block m-0">
              <div className="block-content p-0">
                <img
                  src={`/assets/img/splash/loading-food.gif?v=${this.state.splashVersion}`}
                  className="img-fluid"
                  alt={localStorage.getItem("storeName")}
                  style={{
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Mobile;
