import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyArl0wTtIcUr9IMgFZakq6kTLdnyPhNsqg",
  authDomain: "restaurantify-45393.firebaseapp.com",
  databaseURL: "https://restaurantify-45393-default-rtdb.firebaseio.com",
  projectId: "restaurantify-45393",
  storageBucket: "restaurantify-45393.appspot.com",
  messagingSenderId: "379231212223",
  appId: "1:379231212223:web:4b6ff1f769ce88216eec0a",
  measurementId: "G-D3580W2KBV"
};

// Initialize Firebase
//const appFirebase = initializeApp(firebaseConfig);
export const firebaseInit = initializeApp(firebaseConfig);
export const messaging    = getMessaging(firebaseInit);

/*
export const requestForToken = () => {
    return getToken(messaging, { vapidKey: "BJvyccCwPZzlWgtIcI4c_adLrbxYO-3d5vH9d409a8JtzBfwBKHLcnMvW-ENN0TMwLxIEHpgUtWy9XWzyDjYN2M"})
      .then((currentToken) => {
        if (currentToken) {
          console.log('current token for client: ', currentToken);
          // Perform any other neccessary action with the token
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  };
  */
