import React, { Component } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import Jello from "react-reveal/Jello";
import Ink from "react-ink";
import { Modal } from "react-bootstrap"
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IMAGE_BASE_URL } from "../../../../src/configs/index"
 import "./PWAPrompt.css";
 import { getRestaurantByDomain } from "../../../services/restaurant/actions";
 import { connect } from "react-redux";
 import { GET_RESTAURANT_SLUG_URL } from "../../../../src/configs/index";
import Axios from "axios";
let deferredPrompt;
library.add([faClose,faBullseye])
class PWAInstallation extends Component {
      forceClosePWAInstallPrompt = () =>{
        this.props.onClose();
      }
      handleInstall = () => {
        this.props.onInstallApp();
      };
      render() {
        const {isWindow,} = this.props;

        return (
            <React.Fragment>
				
					        <div className="pwa-prompt mb-10" style={{width: isWindow ?  "25vw" : "100vw"}}>
							<div className="card px-10 py-20">
							<div className="card-header pwa-prompt-header">
								  <a className="mr-10" href="#" onClick={this.forceClosePWAInstallPrompt}><FontAwesomeIcon icon="close" /></a>
							</div>
							<div className="pwa-prompt-body card-body">
								<div className="pwa-prompt-rfy-logo" style={{backgroundColor: localStorage.getItem("storeColor2"),padding: "10px",borderRadius:"1.5rem"}}>
									<img src=  {(localStorage.getItem("mobileLogo") !== null && localStorage.getItem("mobileLogo") !== "null" ) ?  IMAGE_BASE_URL + localStorage.getItem("mobileLogo") : IMAGE_BASE_URL + localStorage.getItem("resImg")}
												alt={localStorage.getItem("activeRestaurantName")}  className="mt-0" /><br />
									</div>
									{/* <img className="pwa-prompt-img" src="">
										
									</img> */}
								<div className="pwa-prompt-info">
								   <div>
										<h5 className="mb-5" style={{fontSize:"12px"}}>
											{localStorage.getItem("activeRestaurantName")}
										</h5>
										<h6 className="mb-5 text-muted" style={{fontSize:"10px"}}>
										   Food Orderinfg -Delivery and Pickup
										</h6>
									</div>
									
								</div>
								<div className="pwa-prompt-action">
						  <button onClick={this.handleInstall} className="btn ripple">Get </button>
								</div>
							</div>
							<div className={`card-footer ${isWindow ? 'px-30' : 'px-10'}`}>
									<p className="my-5 text-muted" style={{fontSize:"9px"}}>
										by Restaurantify
									</p>
									
							</div>
							</div>
						</div>
	
            </React.Fragment>
        )
                            }
                            
}


  
  export default PWAInstallation;

