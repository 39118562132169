import { ADD_REWARD, REMOVE_REWARD } from "./actionTypes";

const initialState = {
    reward: null
};

export default function(state = initialState, action) {
    switch (action.type) {
        case ADD_REWARD:
            return { ...state, reward: action.payload };
          case REMOVE_REWARD:
            return {...state, reward: null}
        default:
            return state;
    }
}
