import {
	GET_RESTAURANT_INFO,
	GET_RESTAURANT_INFO_BY_ID,
	GET_RESTAURANT_ITEMS,
	RESET_INFO,
	RESET_ITEMS,
	RESET_BACKUP,
	SINGLE_ITEM,
	SEARCH_ITEM,
	CLEAR_SEARCH,
	SET_FAVORITE_REST,
	GET_RESTAURANT_INFO_FOR_LOGGED_IN_USER,
	ITEM_ATTRIBUTES,
	GET_RESTAURANT_INFO_BY_DOMAIN
} from "./actionTypes";

import { FORCE_RELOAD } from "../helper/actionTypes";

import {
	GET_RESTAURANT_INFO_BY_ID_URL,
	GET_RESTAURANT_INFO_URL,
	GET_RESTAURANT_SLUG_URL,
	GET_RESTAURANT_ITEMS_URL,
	GET_SINGLE_ITEM_URL,
	GET_RESTAURANT_INFO_AND_OPERATIONAL_STATUS_URL,
	ADD_TO_FAVORITE_RESTAURANT_URL,
	GET_FAVORITE_RESTAURANT_FOR_LOGGED_IN_URL,
	GET_RESTAURANT_CHECKOUT_CONFIG_DETAILS_URL,
	GET_DELIVERY_CHARGE_DETAILS_URL,
	GET_DEFAULT_ADDRESS_DETAILS_URL,
	GET_RESTAURANT_OPEN_STATUS_URL,
	GET_RESTAURANT_LOAD_URL,
	ADD_FAVORITE_ITEM_URL,
	REMOVE_FAVORITE_ITEM_URL,
	GET_FAVORITE_ITEM_URL,
	ITEM_ATTRIBUTES_URL,
	GET_ITEM_BY_MENU_ID_URL,
	GET_DELIVERY_TIME_URL,
	CHECK_DELIVERY_TIME_URL,
	GET_RESTAURANT_ITEMS_BY_IDS,
	GET_REWARDS_BY_RESTAURANT_ID
} from "../../configs";

import Axios from "axios";
import FuzzySearch from "fuzzy-search";
import { IMAGE_BASE_URL } from "../../configs"

export const getRestaurantInfoAndOperationalStatus = (id, latitude, longitude) => (dispatch) => {
	return Axios.post(GET_RESTAURANT_INFO_AND_OPERATIONAL_STATUS_URL, {
		id: id,
		latitude: latitude,
		longitude: longitude,
	})
		.then((response) => {
			const restaurant_info = response.data;
			return dispatch({ type: GET_RESTAURANT_INFO, payload: restaurant_info });
		})
		.catch(function(error) {
			console.log(error);
			if (error.response.status === 400) {
				const force_reload = true;
				return dispatch({ type: FORCE_RELOAD, payload: force_reload });
			}
		});
};

export const getRestaurantInfo = (slug) => (dispatch, getState) => {
	console.log("slug", slug)
	return Axios.post(GET_RESTAURANT_INFO_URL + "/" + slug)
		.then((response) => {
			const restaurant_info = response.data;
			return dispatch({ type: GET_RESTAURANT_INFO, payload: restaurant_info });
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getRestaurantInfoSlug = () => async (dispatch, getState) => {
	//const currentUrlMain = window.location.href;
	//let domainNameArrayMain =  await currentUrlMain.split('/');
	//const MAIN_WEBSITE_DOMAIN_NAME = await (domainNameArrayMain) ? domainNameArrayMain[2] : '';
	const MAIN_WEBSITE_DOMAIN_NAME =  window.location.hostname;

	console.log(GET_RESTAURANT_SLUG_URL + "/" + MAIN_WEBSITE_DOMAIN_NAME);
	//console.log(domainNameArrayMain);
	//const MAIN_WEBSITE_DOMAIN_NAME = 'restaurantify-pnnynd-cuisine-template-font-color.rfydev.site';
	//const MAIN_WEBSITE_DOMAIN_NAME = 'mrs-kumars-indian-cuisine-8p98-cuisine-template-font-color.rfydev.site';
	//const MAIN_WEBSITE_DOMAIN_NAME = 'two-bridges-diner-jmynd-cuisine-template-font-color.rfydev.site';
	
	//console.log(GET_RESTAURANT_SLUG_URL + "/" + MAIN_WEBSITE_DOMAIN_NAME);
	return await Axios.get(GET_RESTAURANT_SLUG_URL + "/" + MAIN_WEBSITE_DOMAIN_NAME)
		.then((response) => {
			const restaurant_info = response.data;	
			if(restaurant_info && restaurant_info.data && restaurant_info.data.redirect){
			   if(process.env.NODE_ENV == 'production' || process.env.NODE_ENV == 'development'){
				window.location.href = `https://mobile.${restaurant_info.data.redirect}`;
				// window.location.href(`mobile.${restaurant_info.data.redirect}`);
			   }else{
				window.location.href = `https://${restaurant_info.data.redirect}:3000`;
				// window.location.href(`${restaurant_info.data.redirect}:3000`);
			   }
			}
			localStorage.setItem('slug', restaurant_info.data.slug);	
			localStorage.setItem('restaurant_id', restaurant_info.data.id);
			localStorage.setItem('rewards_enabled', restaurant_info.data.rewards_enabled);
			localStorage.setItem('rest_slug', restaurant_info.data.slug);
			localStorage.setItem("storeColor", restaurant_info.data.mobile_secondary_theme_color);		
			localStorage.setItem("storeColor2", restaurant_info.data.mobile_primary_theme_color );
			localStorage.setItem("storeLogo", restaurant_info.data.image);
			localStorage.setItem("currencyFormat",restaurant_info.data.currency_symbol);
			localStorage.setItem("phoneCountryCode",`+${restaurant_info.data.dial_code}`);
			localStorage.setItem("currencyId",restaurant_info.data.currency_code);
			localStorage.setItem("restaurantCountry",restaurant_info.data.country_name);
			localStorage.setItem("restuarantCountryCode", restaurant_info.data.country_code);
			localStorage.setItem("updatingMessage", '');
			localStorage.setItem("activeRestaurantName", restaurant_info.data.name);
			localStorage.setItem("rest_date_format", restaurant_info.data.mobile_date_format);
			localStorage.setItem("cartColorBg", restaurant_info.data.mobile_primary_theme_color);
			localStorage.setItem("cartColorText", 'white');
			localStorage.setItem("zone_name",restaurant_info.data.zone_name)
			localStorage.setItem('mobileLogo',restaurant_info.data.mobile_logo);
			localStorage.setItem('earning_reward_conversion',JSON.stringify(restaurant_info.data.earning_reward_conversion));
			//console.log("Slug Data: ", restaurant_info.data);
			 
			return dispatch({ type: GET_RESTAURANT_INFO_BY_DOMAIN, payload: restaurant_info.data });
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getRestaurantInfoForLoggedInUser = (slug,user) => (dispatch, getState) => {
	// token: getState().user.data.auth_token,
	return Axios.post(GET_FAVORITE_RESTAURANT_FOR_LOGGED_IN_URL + "/" + slug, {
		token: user.data.auth_token,
	})
		.then((response) => {
			const restaurant_info = response.data;
			return dispatch({ type: GET_RESTAURANT_INFO_FOR_LOGGED_IN_USER, payload: restaurant_info });
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getRestaurantInfoById = (id) => (dispatch) => {
	return Axios.post(GET_RESTAURANT_INFO_BY_ID_URL + "/" + id)
		.then((response) => {
			console.log("response rest info",response);
			const restaurant_info = response.data;
			return dispatch({
				type: GET_RESTAURANT_INFO_BY_ID,
				payload: restaurant_info,
			});
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getRestaurantItems = (slug,menuId = null,user = null) => (dispatch) => {
	let config = {};
	if (user && user.success) {
	  config = {
		headers: { Authorization: `Bearer ${user.data.auth_token}` },
	  };
	}
	const requestedData = menuId ? {menu_id: menuId} : {};
	Axios.post(GET_RESTAURANT_ITEMS_URL + "/" + slug, requestedData,config)
		.then((response) => {
			const restaurant_items = response.data;
			return dispatch({ type: GET_RESTAURANT_ITEMS, payload: restaurant_items });
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getRestaurantItemsByIds = (ids) => async(dispatch) => {
		return await Axios.post(GET_RESTAURANT_ITEMS_BY_IDS, {
			itemIds: ids,
			}).then((response) => {
				return response.data;
			})
			.catch(function(error) {
				console.log(error);
			});
};

export const getSingleItem = (id) => (dispatch) => {
	return Axios.post(GET_SINGLE_ITEM_URL, {
		id: id,
	})
		.then((response) => {
			const item = response.data;
			return dispatch({ type: SINGLE_ITEM, payload: item });
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const resetItems = () => (dispatch) => {
	const empty = [];
	return dispatch({ type: RESET_ITEMS, payload: empty });
};
export const resetBackup = () => (dispatch) => {
	const empty = [];
	return dispatch({ type: RESET_BACKUP, payload: empty });
};

export const resetInfo = () => (dispatch) => {
	const empty = [];
	return dispatch({ type: RESET_INFO, payload: empty });
};

export const searchItem = (itemList, itemName, searchFoundText, noResultText) => (dispatch, getState) => {
	const searchResultText = searchFoundText + itemName;
	const noSearchFoundText = noResultText + itemName;

	let foodItems = [];

	const searcher = new FuzzySearch(itemList, ["name"], {
		caseSensitive: false,
	});
	foodItems = searcher.search(itemName);
	if (foodItems.length > 0) {
		return dispatch({
			type: SEARCH_ITEM,
			payload: { items: { [searchResultText]: foodItems } },
		});
	} else if (foodItems.length <= 0) {
		return dispatch({
			type: SEARCH_ITEM,
			payload: { items: { [noSearchFoundText]: foodItems } },
		});
	}
};

export const clearSearch = (data) => (dispatch) => {
	return dispatch({ type: CLEAR_SEARCH, payload: data });
};

export const setFavoriteRest = (token, rest_id) => (dispatch) => {
	return Axios.post(ADD_TO_FAVORITE_RESTAURANT_URL, {
		token: token,
		id: rest_id,
	})
		.then((response) => {
			const restaurant = response.data;
			return dispatch({ type: SET_FAVORITE_REST, payload: restaurant });
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getRestaurantCheckoutConfigDetails = (menuId = null) => async(dispatch) => {
	let slug = localStorage.getItem('rest_slug');
	//console.log('Checkout Slug : ', slug);
	//console.log('GET_RESTAURANT_CHECKOUT_CONFIG_DETAILS_URL : ', GET_RESTAURANT_CHECKOUT_CONFIG_DETAILS_URL);
	if(slug){
		let requestData = {
			restaurant_slug: slug
		}
		if(menuId != null){
		  requestData.menu_id = menuId;
		}
		return  await Axios.post(GET_RESTAURANT_CHECKOUT_CONFIG_DETAILS_URL, requestData).then((response) => {
				return response.data;
			})
			.catch(function(error) {
				console.log(error);
			});
	}	
};

export const getDeliveryCharges = (data) => async(dispatch) => {
	console.log("tips_perc::::",localStorage.getItem('tips_perc'));
	let slug = localStorage.getItem('rest_slug');
	let dtype = 'delivery';
	if(localStorage.getItem("checkout_delivery_method") === 'DoorDash') {
		dtype = 'doordash';
	}
	if(slug){
		let deliveryChargeApiDetails = {
			restaurant_slug: slug,
			order_type: localStorage.getItem('checkout_type'),
			delivery_method: localStorage.getItem("checkout_delivery_method"),
			partner_code: localStorage.getItem("checkout_delivery_method"), // "doordash",
			pickup_type: localStorage.getItem('checkout_time_type'),
			tip_percent:(localStorage.getItem('tips_perc') && localStorage.getItem('tips_perc') !== "") ? localStorage.getItem('tips_perc') : 15,
			tips_amount: localStorage.getItem('tips_amount'),
			delivery_first_name: data.first_name,
			delivery_last_name: data.last_name,
			delivery_phone: data.phone,
			delivery_email: data.email,
			delivery_address1: data.address1,
			delivery_address2: data.address2,
			delivery_city: data.city,
			delivery_state: data.add_state,
			delivery_zip: data.postalCode,
			online_order: 1,
			currency: "usd",
			countryCode: "us",
			country: "unitedstates",
			amount: data.cart_total

			/*delivery_first_name: "pasupathi",
			delivery_last_name: "kalamegam",
			delivery_phone: "(732)666-5035",
			delivery_email: "priya@synergent.net",
			delivery_address1: "1600 Perrineville Rd",
			delivery_address2: "Ste 57",
			delivery_city: "Monroe Township",
			delivery_state: "NJ",
			delivery_zip: "08831",
			online_order: 1,
			currency: "usd",
			countryCode: "us",
			country: "unitedstates",
			amount: 60.00*/
			//paymentMethod:2,
			//card_name:pasupathikalamegam,
		};
		console.log("deliveryChargeApplied::::::",JSON.stringify(deliveryChargeApiDetails));

		return await Axios.post(GET_DELIVERY_CHARGE_DETAILS_URL, deliveryChargeApiDetails).then((response) => {
			console.log("getDeliveryCharges",response.data);
				return response.data;
			})
			.catch(function(error) {
				console.log("getDeliveryChargesError",error);
			});
	}	
};

export const getDefaultAddress = (user) => (dispatch) => {
	const config = {
		headers: { Authorization: `Bearer ${user.data.auth_token}` }
	};
	return Axios.post(GET_DEFAULT_ADDRESS_DETAILS_URL, [], config)
		.then((response) => {
			return response.data;
		})
		.catch(function(error) {
			return error.response;
		});
};

export const getRestaurantOpenStatus = (schedule_date) => (dispatch) => {
	
	let slug = localStorage.getItem('rest_slug');
	if(slug){
		console.log({
			restaurant_slug: slug,
			date: schedule_date,				
		});
		return Axios.post(GET_RESTAURANT_OPEN_STATUS_URL, {
				restaurant_slug: slug,
				date: new Date(schedule_date.getTime() - (schedule_date.getTimezoneOffset() * 60000)).toJSON(),				
			}).then((response) => {
				return response.data;
			})
			.catch(function(error) {
				console.log(error);
			});
	}	
};

export const checkOrderItemDeliveryTime = (deliveryTimeInfo) => (dispatch) => {
	const inputData = {
		restaurant_slug:deliveryTimeInfo.restaurant_slug,
		user_access_token:deliveryTimeInfo.user_access_token,
		order_type:deliveryTimeInfo.order_type,
		pickup_type:deliveryTimeInfo.pickup_type,
		menu_id:deliveryTimeInfo.menu_id,
		cart_items:deliveryTimeInfo.cart_items,
		scheduled_date:deliveryTimeInfo.scheduled_date
	}
		return Axios.post(CHECK_DELIVERY_TIME_URL,inputData).then((response) => {
				return response.data;
			})
			.catch(function(error) {
				console.log(error);
			});
	}	


export const getOrderItemDeliveryTime = (deliveryTimeInfo) => (dispatch) => {
		const inputData = {
			restaurant_slug:deliveryTimeInfo.restaurant_slug,
			user_access_token:deliveryTimeInfo.user_access_token,
			order_type:deliveryTimeInfo.order_type,
			pickup_type:deliveryTimeInfo.pickup_type,
			menu_id:deliveryTimeInfo.menu_id,
			cart_items:deliveryTimeInfo.cart_items,
			scheduled_date:deliveryTimeInfo.scheduled_date
		}
		return Axios.post(GET_DELIVERY_TIME_URL,inputData).then((response) => {
				return response.data;
			})
			.catch(function(error) {
				console.log(error);
			});
	}


export const getRestaurantLoadData = () => async () => {
	const MAIN_WEBSITE_DOMAIN_NAME =  window.location.hostname;
	return await Axios.post(GET_RESTAURANT_LOAD_URL, {
			slug_or_domain: MAIN_WEBSITE_DOMAIN_NAME,
		})
		.then((response) => {
			const restaurant_load_data = response.data;
			localStorage.setItem("restaurantLoader", `${IMAGE_BASE_URL}${restaurant_load_data.data.mobile_loader}`);
			localStorage.setItem("restaurantSplashScreen",`${IMAGE_BASE_URL}${restaurant_load_data.data.mobile_splash_screen}`);
			return restaurant_load_data.data;
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const selectedFavoriteItems = (user, itemId, type, favorite_id) => async () => {
	let slug = localStorage.getItem('rest_slug');
	const config = {
		headers: { Authorization: `Bearer ${user.data.auth_token}` }
	};
	let action_url = '';
	let params = {};
	if(type === 'add') {
		action_url = ADD_FAVORITE_ITEM_URL;
		params = {
			item_id: itemId,
			restaurant_slug: slug,
			notes: null,
		};
	} else {
		action_url = REMOVE_FAVORITE_ITEM_URL;
		params = {
			id: favorite_id,
			restaurant_slug: slug,
			notes: null,
		};
	}
	return await Axios.post(action_url, params, config)
		.then((response) => {
			const restaurant_load_data = response.data;
			//console.log('Fav Success Data: ', restaurant_load_data.data);
			return restaurant_load_data.data;
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getFavoriteItemsList = (user,menuId = null) => async () => {
	let slug = localStorage.getItem('rest_slug');
	const config = {
		headers: { Authorization: `Bearer ${user.data.auth_token}` }
	};
	
	const requestedData  = menuId ? {restaurant_slug: slug,menu_id: menuId} : {restaurant_slug: slug};
	return await Axios.post(GET_FAVORITE_ITEM_URL, 
		requestedData , config)
		.then((response) => {
			const restaurant_load_data = response.data;
			return restaurant_load_data.data; 
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getItemByMenuId = (menuId) => async() => {	
	let slug = localStorage.getItem('rest_slug');
	return await Axios.post(GET_ITEM_BY_MENU_ID_URL, {
		restaurant_slug: slug,
		menu_id: menuId
		})
		.then((response) => {
			const restaurant_data = response.data;
			return restaurant_data.data;
		})
		.catch(function(error) {
			console.log(error);
		});
}

export const getItemAttributes = (resId) => {
	return new Promise((resolve, reject) => {
	  Axios.get(`${ITEM_ATTRIBUTES_URL}/${resId}`)
		.then((response) => {
		  const attributes = response.data;
		  resolve(attributes);
		})
		.catch((error) => {
		  reject(error);
		});
	});
  };

  export const getRewardsByRestaurantId = (resId) => async (dispatch) => {
	try {
	  const response = await Axios.get(`${GET_REWARDS_BY_RESTAURANT_ID}/${resId}`);
	  return response.data;
	} catch (error) {
	  console.error('Error fetching attributes:', error);
	  throw error; // Re-throw the error to be caught by the caller
	}
  };
  
