import {
	LOGIN_USER,
	REGISTER_USER,
	LOGOUT_USER,
	UPDATE_USER_INFO,
	SEND_OTP,
	VERIFY_OTP,
	RUNNING_ORDER,
	GET_WALLET_TRANSACTIONS,
	SEND_PASSWORD_RESET_EMAIL,
	VERIFY_PASSWORD_RESET_OTP,
	CHANGE_USER_PASSWORD,
	SAVE_VAT_NUMBER,
	SOCIAL_LOGIN_USER,
	GET_OTP,
	VERIFY_OTP_USER,
	REGISTER_OTP_USER
} from "./actionTypes";
import {
	LOGIN_USER_URL,
	REGISTER_USER_URL,
	UPDATE_USER_INFO_URL,
	SEND_OTP_URL,
	VERIFY_OTP_URL,
	CHECK_USER_RUNNING_ORDER_URL,
	GET_WALLET_TRANSACTIONS_URL,
	SEND_PASSWORD_RESET_EMAIL_URL,
	VERIFY_PASSWORD_RESET_OTP_URL,
	CHANGE_USER_PASSWORD_URL,
	CHANGE_USER_AVATAR_URL,
	CHECK_BAN_URL,
	SAVE_VAT_NUMBER_URL,
	SOCIAL_LOGIN_URL,
	GET_OTP_URL,
	VERIFY_OTP_PHONE,
	VERIFY_PHONE_OTP_URL,
	FORGOTPASSWORD_URL,
	RESET_PASSWORD_URL,
	FORGOTPASSOWRD_OTP_VERIFY
} from "../../configs";
import { GET_ADDRESSES } from "../addresses/actionTypes";
import { updateCart } from "../total/actions";
import Axios from "axios";

export const loginUser = (name, email, password, accessToken, phone, provider, address, slug) => (dispatch) => {
	Axios.post(LOGIN_USER_URL, {
		name: name,
		email: email,
		password: password,
		accessToken: accessToken,
		phone: phone,
		provider: provider,
		address: address,
		slug_or_domain: slug
	})
		.then((response) => {
			const user = response.data;
			console.log("loginuser::::::",user);
			if(user){
				let addressData = user.data.default_address;
				if(addressData && addressData !== null){
					localStorage.setItem('default_add_first_name', (addressData.first_name && addressData.first_name !== null  && addressData.first_name !== 'null') ? addressData.first_name : '');
					localStorage.setItem('default_add_last_name', (addressData.last_name && addressData.last_name !== null  && addressData.last_name !== 'null') ? addressData.last_name : '');
					localStorage.setItem('default_add_email', (addressData.email && addressData.email !== null  && addressData.email !== 'null') ? addressData.email : '');
					localStorage.setItem('default_add_mobile', (addressData.mobile && addressData.mobile !== null  && addressData.mobile !== 'null') ? addressData.mobile : '');
					localStorage.setItem('default_add_address1', (addressData.address1 && addressData.address1 !== null  && addressData.address1 !== 'null') ? addressData.address1 : '');
					localStorage.setItem('default_add_address2', (addressData.address2 && addressData.address2 !== null  && addressData.address2 !== 'null') ? addressData.address2 : '');
					localStorage.setItem('default_add_city', (addressData.city && addressData.city !== null  && addressData.city !== 'null') ? addressData.city : '');
					localStorage.setItem('default_add_state', (addressData.state && addressData.state !== null  && addressData.state !== 'null') ? addressData.state : '');
					localStorage.setItem('default_add_zip_code', (addressData.zip_code && addressData.zip_code !== null  && addressData.zip_code !== 'null') ? addressData.zip_code : '');
				}
			}
			return dispatch({ type: LOGIN_USER, payload: user });
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const registerUser = (name, email, phone, password, address, slug) => (dispatch) => {
	Axios.post(REGISTER_USER_URL, {
		name: name,
		email: email,
		phone: phone,
		password: password,
		address: address,
		slug_or_domain: slug
	})
		.then((response) => {
			const user = response.data;
			return dispatch({ type: REGISTER_USER, payload: user });
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const logoutUser = () => (dispatch,getState) => {
	const user = [];
	const walletEmpty = [];
	const emptyAddress = [];
	const state = getState();
	const cartProducts = state.cart.products;

	for (let i = cartProducts.length - 1; i >= 0; i--) {
		cartProducts.splice(i, 1);
	}
	dispatch(updateCart(cartProducts));
	localStorage.removeItem("userSetAddress");
	localStorage.removeItem("geoLocation");
	localStorage.removeItem("lastSavedNotificationToken");
	localStorage.removeItem('checkout_time');
	localStorage.removeItem('checkout_time_type');
	localStorage.removeItem('checkout_type');
    localStorage.removeItem('selectedPickupTime');
	localStorage.removeItem('selectedScheduledDate');
	return [
		dispatch({
			type: LOGOUT_USER,
			payload: user,
		}),
		dispatch({
			type: RUNNING_ORDER,
			payload: false,
		}),
		dispatch({
			type: GET_WALLET_TRANSACTIONS,
			payload: walletEmpty,
		}),

		dispatch({
			type: GET_ADDRESSES,
			payload: emptyAddress,
		}),
	];
};

export const updateUserInfo = (user_id, token, unique_order_id,restaurant_id) => (dispatch) => {
	console.log('restaurant_id::::::::::::::::',restaurant_id);
	return Axios.post(UPDATE_USER_INFO_URL, {
		token: token,
		user_id: user_id,
		unique_order_id: unique_order_id,
		restaurant_id: restaurant_id
	})
		.then((response) => {
			const user = response.data;
			return dispatch({ type: UPDATE_USER_INFO, payload: user });
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const checkUserRunningOrder = (user_id, token) => (dispatch) => {
	Axios.post(CHECK_USER_RUNNING_ORDER_URL, {
		token: token,
		user_id: user_id,
	})
		.then((response) => {
			const running_order = response.data;
			return dispatch({ type: RUNNING_ORDER, payload: running_order });
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const sendOtp = (email, phone, accessToken, provider) => (dispatch) => {
	return Axios.post(SEND_OTP_URL, {
		email: email,
		phone: phone,
		accessToken: accessToken,
		provider: provider,
	})
		.then((response) => {
			const user = response.data;
			return dispatch({ type: SEND_OTP, payload: user });
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const verifyOtp = (phone, otp) => (dispatch) => {
	Axios.post(VERIFY_OTP_URL, {
		phone: phone,
		otp: otp,
	})
		.then((response) => {
			const user = response.data;
			return dispatch({ type: VERIFY_OTP, payload: user });
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getWalletTransactions = (token, user_id) => (dispatch) => {
	Axios.post(GET_WALLET_TRANSACTIONS_URL, {
		token: token,
		user_id: user_id,
	})
		.then((response) => {
			const wallet = response.data;
			return dispatch({ type: GET_WALLET_TRANSACTIONS, payload: wallet });
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const sendPasswordResetMail = (email) => (dispatch) => {
	Axios.post(SEND_PASSWORD_RESET_EMAIL_URL, {
		email: email,
	})
		.then((response) => {
			const data = response.data;
			return dispatch({ type: SEND_PASSWORD_RESET_EMAIL, payload: data });
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const verifyPasswordResetOtp = (email, code) => (dispatch) => {
	Axios.post(VERIFY_PASSWORD_RESET_OTP_URL, {
		email: email,
		code: code,
	})
		.then((response) => {
			const data = response.data;
			return dispatch({ type: VERIFY_PASSWORD_RESET_OTP, payload: data });
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const changeUserPassword = (email, code, password) => (dispatch) => {
	Axios.post(CHANGE_USER_PASSWORD_URL, {
		email: email,
		code: code,
		password: password,
	})
		.then((response) => {
			const data = response.data;
			return dispatch({ type: CHANGE_USER_PASSWORD, payload: data });
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const changeAvatar = (token, avatar) => () => {
	return Axios.post(CHANGE_USER_AVATAR_URL, {
		token: token,
		avatar: avatar,
	})
		.then((response) => {
			const data = response.data;
			return data;
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const checkBan = (token) => () => {
	return Axios.post(CHECK_BAN_URL, {
		token: token,
	})
		.then((response) => {
			const data = response.data;
			return data;
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const saveVATNumber = (token, vatNumber) => (dispatch) => {
	return Axios.post(SAVE_VAT_NUMBER_URL, {
		token: token,
		tax_number: vatNumber,
	})
		.then((response) => {
			const data = response.data;
			return dispatch({ type: SAVE_VAT_NUMBER, payload: data });
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const socialLogin = (name, email,slug) => (dispatch) => {
	Axios.post(SOCIAL_LOGIN_URL, {
		name: name,
		email: email,
		slug_or_domain: slug
	})
		.then((response) => {
			const user = response.data;
			return dispatch({ type: SOCIAL_LOGIN_USER, payload: user });
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getOtpForPhoneVerification = (phone) => {
	return new Promise((resolve, reject) => {
	  Axios.post(GET_OTP_URL, {
		phoneNumber: phone,
	  })
		.then((response) => {
		  const user = response.data;
		  resolve(user);
		})
		.catch((error) => {
		  reject(error);
		});
	});
  };


  export const phoneNoOTPVerification = (phone, otp) => {
    return Axios.post(VERIFY_OTP_PHONE, {
        phoneNumber: localStorage.getItem("phoneCountryCode") + phone,
        otp: otp
    });
};


export const otpVerification = (data) => (dispatch) => {
	 Axios.post(VERIFY_PHONE_OTP_URL, {
		phoneNumber: data.phone,
		slug_or_domain: data.slug,
		restaurant_id: data.restaurant_id
	})
		.then((response) => {
			const user = response.data;
			if(user.success){
				return dispatch({ type: VERIFY_OTP_USER, payload: user });
			}
			else{
				return dispatch({ type: REGISTER_OTP_USER, payload: user})
			}
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const forgotPasswordOTP = (email,slug) => {
	return new Promise((resolve, reject) => {
	  Axios.post(FORGOTPASSWORD_URL, {
		email: email,
		res_slug: slug
	  })
		.then((response) => {
		  const user = response;
		  resolve(user);
		})
		.catch((error) => {
		  reject(error);
		});
	});
  };

  export const forgotPasswordOTPVerification = async (token, otp) => {
	try {
	  const response = await Axios.post(FORGOTPASSOWRD_OTP_VERIFY, {
		email: token,
		otp: otp
	  });
	  return response;
	} catch (error) {
	  throw error;
	}
  };
  

  export const passwordReset = (data) => {
	return new Promise((resolve, reject) => {
	  Axios.post(RESET_PASSWORD_URL, {
		email: data.email,
		password: data.password
	  })
		.then((response) => {
		  const user = response.data;
		  resolve(user);
		})
		.catch((error) => {
		  reject(error);
		});
	});
  };
