export const formatPrice = (price,roundOff = 2) => {
    // if (price) {
    //     let formatedPrice = parseFloat(price);
    //     formatedPrice = formatedPrice.toFixed(3);
    //     const factor = Math.pow(10, roundOff)
    //     let value = Math.round(formatedPrice * factor) / factor
    //     console.log('a::::::::::::',value);
    //     return value.toFixed(2);
    // }
    // return 0;

    if (price) {
        let formatedPrice = parseFloat(price);
        formatedPrice = formatedPrice.toFixed(roundOff);
        return formatedPrice;
    }
    return 0;
};

export const formatPriceForReward = (price, roundOff = 2) => {
    if (price) {
        let value = parseFloat(price.toFixed(3)); 
        const factor = Math.pow(10, roundOff);
        const roundedValue = Math.round(value * factor) / factor;
        return roundedValue.toFixed(roundOff); 
    }
    return '0.00'; 
};


export const taxCalc = (percentage,totalAmt,roundOff = 2) => {
    const tax_amt = parseFloat(totalAmt)*(parseFloat(percentage)/100);
     return formatPrice(tax_amt,roundOff);
}
