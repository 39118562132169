import React, { Component } from "react";
import Footer from "../Footer";
import Hero from "../Hero";
import StoreAchievements from "../StoreAchievements";
import { connect } from "react-redux";
import {getRestaurantInfoSlug} from "../../../services/items/actions";

class V1 extends Component {

   async componentDidMount(){
	if(!localStorage.getItem("storeColor2") || !localStorage.getItem("storeColor") ){
	await this.props.getRestaurantInfoSlug();
	}
   }
	
	render() {
		return (
			<React.Fragment>
				{(localStorage.getItem("storeColor2") || localStorage.getItem("storeColor") ) && (
				  <Hero restaurant_website_info={this.props.restaurant_website_info} itemId={this.props.itemId} />
				)}
				
				{/* <StoreAchievements />
				<Footer languages={this.props.languages} handleOnChange={this.props.handleOnChange} /> */}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({

});
export default connect(
	mapStateToProps,
	{ getRestaurantInfoSlug}
)(V1);

