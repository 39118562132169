import { GET_ADDRESSES, SAVE_ADDRESS, DELETE_ADDRESS, SET_DEFAULT, UPDATE_ADDRESS } from "./actionTypes";

const initialState = {
    addresses: [],
    addAddress:[],
    updateAddress:[]
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_ADDRESSES:
            return { ...state, addresses: action.payload };
        case SAVE_ADDRESS:
            return { ...state, addAddress: action.payload };
        case DELETE_ADDRESS:
            return { ...state, addresses: action.payload };
        case SET_DEFAULT:
            return { ...state, addresses: action.payload };
            case UPDATE_ADDRESS:
            return { ...state, updateAddress: action.payload};
        default:
            return state;
    }
}
