import { GET_SETTINGS } from "./actionTypes";
import { GET_SETTINGS_URL } from "../../configs";
import Axios from "axios";

export const getSettings = () => (dispatch) => {
	Axios.post(GET_SETTINGS_URL)
		.then((response) => {
			const settings = response.data;
			if(settings){
				const filteredSettings = settings.filter(setting => setting.key !== 'currencyFormat' && setting.key !== 'storeColor' && setting.key !== 'phoneCountryCode' && setting.key !== 'currencyId');
				filteredSettings.map(x => {
					//console.log(x.key, x.value);
					localStorage.setItem(x.key, x.value);
					return x;
				});
			}
			localStorage.setItem("footerHome", "Home");
			localStorage.setItem("footerCart", 'Cart');
			localStorage.setItem("footerFavourite", 'Favourite');
			localStorage.setItem("footerAccount", 'Account');
			localStorage.setItem("deliveryFooterAccount", 'Account');
			localStorage.setItem("footerAlerts", 'Alerts');
			localStorage.setItem("footerHome", 'Home');
			localStorage.setItem("footerNearme", 'Near Me');
			localStorage.setItem("footerExplore", 'Explore');
			return dispatch({ type: GET_SETTINGS, payload: settings });
		})
		.catch(function(error) {
			console.log(error);
		});
};
