import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
//import React from "react";
import store from "./services/store";
//import ManifestHandler from "./manifestHandler.js";
import CheckVersion from "./components/CheckVersion";
import { isAndroid,isIOS } from "react-device-detect";
import PWAPrompt from "react-ios-pwa-prompt";
import { Offline, Online } from "react-detect-offline";
import OfflineComponent from "./components/Mobile/OfflineComponent";
import CustomCssProvider from "./components/CustomCssProvider";
import PWAInstallation from "./components/Mobile/PWAInstallation";
import React, { useEffect,useState } from 'react';
import generateManifest from './generateManifest';
import { messaging } from "./firebase";
import { getToken } from "firebase/messaging";
import {GET_RESTAURANT_SLUG_URL} from "../src/configs";
import Axios from "axios";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IMAGE_BASE_URL } from "../src/configs/index";
import { connect } from "react-redux";


let deferredPrompt;
library.add([faClose,faBullseye])
//const maniFestData =  await generateManifest(process.env.REACT_APP_API_URL,window.location.hostname);
const polling = {
	enabled: false,
};

const Root = ({ children, initialState = {} }) => {
	const [isWindowInstallable, setIsWindowInstallable] = useState(false);
	const [deferredPrompt, setDeferredPrompt] = useState(null);
	const [isAndroidInstalled, setIsAdroidInstalled] = useState(false);
	const [resAPIData, setResAPIData] = useState(null);
	  useEffect(() => {
	    setCanonicalLink();
		window.addEventListener("beforeinstallprompt", async(e) => {
			// setTimeout(() => {
				// Check if it's a web platform
				// if (e.platforms && e.platforms.includes('web')) {
				//   if (!isAndroid && !isIOS) {
				// 	setIsWindowInstallable(true);
				// 	setDeferredPrompt(e);
				// 	return;
				//   }
				// }
			// }, 30000);
			
			e.preventDefault();
			// if(isAndroid){
			
			// 	setIsAdroidInstalled(true);
			// 	setDeferredPrompt(e);
				
			// 	return;
			// }
	  	});
	  });

  const setCanonicalLink = () => {
	const canonicalLink = document.getElementById('canonical-link');
	if (canonicalLink) {
		 canonicalLink.setAttribute('href', window.location.href);
	}
};

	const forceCloseWindowInstallPrompt = () =>{
		setIsWindowInstallable(false);
		setDeferredPrompt(null);
		return;
		
	 }

	 const forceCloseAndroidInstallPrompt = () =>{
		
		setIsAdroidInstalled(false);
		setDeferredPrompt(null);	
		return;
	 }

	 const handleInstall = (isAndroid) => {
		if (deferredPrompt) {
		  deferredPrompt.prompt();
		  deferredPrompt.userChoice.then((choiceResult) => {
			if (choiceResult.outcome === 'accepted') {
			  console.log('User accepted the install prompt');
			} else {
			  console.log('User dismissed the install prompt');
			}
		  });
		  if (isAndroid) {
			forceCloseAndroidInstallPrompt();
		  } else {
			forceCloseWindowInstallPrompt();
		  }
		} else {
		  console.log('Prompt not available');
		}
	  };
	 
	
	  

return(
	<React.Fragment>	
				{( isAndroidInstalled  && isAndroid) && (
	<PWAInstallation  isWindow={false} onClose={forceCloseAndroidInstallPrompt} onInstallApp={() => handleInstall(true)}/>
		 )}

{(isWindowInstallable && !isAndroid) && (
           <PWAInstallation  isWindow={true} onClose={forceCloseWindowInstallPrompt} onInstallApp={() => handleInstall(false)}/>
		 )}
		<Provider store={store(initialState)}>
			<CustomCssProvider />
			<Online polling={polling}>
				{children}
				<img className="cart-empty-img hidden" src="/assets/img/various/offline.png" alt="offline" />
				<CheckVersion />
				{(isIOS) &&(
					<PWAPrompt
						 delay={1500}
						 timesToShow = {1000}
						 permanentlyHideOnDismiss = {false}
						copyTitle={localStorage.getItem("iOSPWAPopupTitle") != null ? localStorage.getItem("iOSPWAPopupTitle") :"Add to Home Screen"}
						copyBody={localStorage.getItem("iOSPWAPopupBody") != null ? localStorage.getItem("iOSPWAPopupBody") : "This website has app functionality. Add it to your home screen to use it in fullscreen and while offline."}
						copyShareButtonLabel={localStorage.getItem("iOSPWAPopupShareButtonLabel") != null ? localStorage.getItem("iOSPWAPopupShareButtonLabel") : "1) Press the 'Share' button"}
						copyAddHomeButtonLabel={localStorage.getItem("iOSPWAPopupAddButtonLabel") != null ? localStorage.getItem("iOSPWAPopupAddButtonLabel") : "2) Press 'Add to Home Screen"}
						copyClosePrompt={localStorage.getItem("iOSPWAPopupCloseButtonLabel") != null ? localStorage.getItem("iOSPWAPopupCloseButtonLabel") : "Cancel"}
					/>
				)}
	
					
			</Online>
			<Offline polling={polling}>
				<OfflineComponent />
			</Offline>
		</Provider>
	</React.Fragment>
);
};
localStorage.setItem('api_url',process.env.REACT_APP_API_URL);
const  GenerateManifestData = async() =>{
	const maniFestData =  await generateManifest(process.env.REACT_APP_API_URL,window.location.hostname);
	return maniFestData;
}
const iconSource = (icon) => {
	let baseURL = window.location.origin.replace("https://","").replace("http://","")
	return icon.src.replace(baseURL,"");
}
const bindManifestData = () => {
	 try{
		const manifestData =  GenerateManifestData();
		manifestData.then((response) => {
			let manifest = response;
			console.log("intial response",manifest)
			if(manifest.icons && manifest.icons.length > 0){
				manifest.icons = manifest.icons.map(x => {x.src = iconSource(x); return x;});
			}
			
			const manifestTag = document.createElement('link');
			const title = document.getElementById('app-title');
			document.title = manifest.name;
			const iconRes1 = document.getElementById('iconRes1');
			const icon1 = manifest.icons ? manifest.icons.find(a => a.sizes === '36x36') : null;
			//icon1.src = iconSource(icon1);
			iconRes1.setAttribute('href', icon1.src);
			const iconRes2 = document.getElementById('iconRes2');
			const icon2 = manifest.icons ? manifest.icons.find(a => a.sizes === '48x48') : null;
			//icon2.src = new URL(icon2.src, window.location.origin).toString();
			//icon2.src = iconSource(icon2);
			iconRes2.setAttribute('href',icon2.src);
			const iconRes3 =  document.getElementById('iconRes3');
			const icon3 = manifest.icons ? manifest.icons.find(a => a.sizes === '96x96') : null;
			// icon3.src = new URL(icon3.src, window.location.origin).toString();
			//icon3.src = iconSource(icon3);
			iconRes3.setAttribute('href',icon3.src );
			const iconRes4 = document.getElementById('iconRes4');
			const icon4 = manifest.icons ? manifest.icons.find(a => a.sizes === '128x128') : null;
			// icon4.src = new URL(icon4.src, window.location.origin).toString();
			//icon4.src = iconSource(icon4);
			iconRes4.setAttribute('href', icon4.src);
			const iconRes5 =  document.getElementById('iconRes5');
			const icon5 = manifest.icons ? manifest.icons.find(a => a.sizes === '96x96') : null;
			iconRes5.setAttribute('href', icon5.src);
			manifestTag.setAttribute('rel', 'manifest');
			// console.log("manifest data",manifest);
			// manifestTag.setAttribute('href', URL.createObjectURL(new Blob([JSON.stringify(manifest)], { type: 'manifest+json' })));
			var blob = new Blob([JSON.stringify(manifest)], { type: 'application/json' });
            var blobURL = URL.createObjectURL(blob);
			var link = document.querySelector('link[rel="manifest"]');
			link.href = blobURL;
			// document.head.appendChild(manifestTag);
		})
	}
	catch(Exception){
		console.log("Error Binding Manifest data")
	}

}

async function fontThemeChange() {
	const MAIN_WEBSITE_DOMAIN_NAME =  window.location.hostname;
	 await Axios.get(GET_RESTAURANT_SLUG_URL + "/" + MAIN_WEBSITE_DOMAIN_NAME)
		.then((response) => {
			const restaurant_info = response.data;	
			const data = restaurant_info.data;
			if(data.mobile_primary_font_name == "" || data.mobile_primary_font_name === ""){
				document.body.style.fontFamily = "'Outfit', sans-serif";
				document.documentElement.style.fontFamily = "'Outfit', sans-serif";

			}
			else{
				document.body.style.fontFamily = data.mobile_primary_font_name;
				document.documentElement.style.fontFamily = data.mobile_primary_font_name;

			}
		});
	
}

async function getTokenFCM () {
	const permission = await Notification.requestPermission();
	console.log(permission);
	if (permission === "granted") {
      // Generate Token
      const token = await getToken(messaging);
      console.log("Token Gen", token);
	  localStorage.setItem("deviceToken",token);
      // Send this token  to server ( db)
    } else if (permission === "denied") {
      console.log("You denied for the notification");
    }
}




getTokenFCM();
bindManifestData();
fontThemeChange();

serviceWorker.register();


export default Root;
