import { any } from "prop-types";
import { GET_ORDERS, CANCEL_ORDER,GET_ORDER_BY_ID,GET_ORDER_STATUS } from "./actionTypes";

const initialState = {
    orders: [],
    order_detail:{},
    cancel: null,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_ORDERS:
            return { ...state, orders: action.payload };
        case CANCEL_ORDER:
            return { ...state, cancel: action.payload };
        case GET_ORDER_BY_ID:
            return {...state,order_detail:action.payload};
        default:
            return state;
    }
}
