//import WebpackPwaManifest  from 'webpack-pwa-manifest'
import React, { useState, useEffect } from "react";
import DefaultManiFestData from './defaultManifest.json';
//var WebpackPwaManifest = require('webpack-pwa-manifest');
const path = require("path");
const  getAPIManifestData = async(api_url,payload) =>{
    let config = {
        method: 'POST',
        body : JSON.stringify({slug_or_domain: payload}),
        headers :  {"Content-Type" : "application/json; charset=UTF-8"}
    }
   const responseData = await fetch(api_url+"/api/template/restaurant/config",config).then((response) => response.json());
   return responseData;
}
function getIconResource(icons,url,originalImage){
      icons = icons.map(icon => {
         let imagePath = path.join(url,originalImage?originalImage:icon['src']);
         icon['src'] = imagePath;
         return icon;
      })
     return icons;
}
const  generateManifest = async(api,payload) => {
  let defaultManiFestTemp =  DefaultManiFestData;
  try{
    const manifestAPIPromise =   await getAPIManifestData(api,payload);
   
    const  manifestAPIData = manifestAPIPromise.data

    let mobileLogo = manifestAPIData.mobile_logo ? manifestAPIData.mobile_logo : manifestAPIData.image;
      defaultManiFestTemp["short_name"] = manifestAPIData.name?manifestAPIData.name:defaultManiFestTemp["short_name"];
      defaultManiFestTemp["name"] = manifestAPIData.name?manifestAPIData.name:defaultManiFestTemp["name"];
      defaultManiFestTemp['icons'] = manifestAPIData.icons?getIconResource(manifestAPIData.icons,api,mobileLogo):getIconResource(defaultManiFestTemp['icons'],api,mobileLogo);
     defaultManiFestTemp['start_url'] = window.location.origin;
     defaultManiFestTemp['id'] = window.location.origin;
     //defaultManiFestTemp['scope'] = "/";
     console.log("defaultManiFestTemp['icons']",defaultManiFestTemp['icons'])
  }
 catch(Exception){
    console.log("Error on Generating manifest data")
 }
// });
  
//   defaultManiFestTemp["scope"] = manifestAPIData.scope?manifestAPIData.dir:defaultManiFestTemp["scope"];;
//   defaultManiFestTemp["start_url"] = manifestAPIData.start_url?manifestAPIData.dir:defaultManiFestTemp["start_url"];;
//   defaultManiFestTemp["display"] = manifestAPIData.display?manifestAPIData.display:defaultManiFestTemp["display"];;
//   defaultManiFestTemp["theme_color"] = manifestAPIData.dir?manifestAPIData.dir:defaultManiFestTemp["dir"];;
//   defaultManiFestTemp["background_color"] = manifestAPIData.dir?manifestAPIData.dir:defaultManiFestTemp["dir"];;
//defaultManiFestTemp["dir"] = manifestAPIData.dir?manifestAPIData.dir:defaultManiFestTemp["dir"];
 
  return defaultManiFestTemp;
  }
  export default generateManifest
  
  
